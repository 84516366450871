import Vue from 'vue'
import platon from '@realsoft/platon-core'
import store from './store'
import router from './router'



Vue.use(platon, {
  store,
  router
})

Vue.config.productionTip = false
